<template>
    <div>
        <apexchart height=400 :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
export default {
name: "gasto",
data() {
    return {
      chartOptions: {
        chart:{
          type:'bar',
          stacked:false,
        },
        plotOptions: {
          bar: {
            horizontal: false
          }
        },
        dataLabels: {
              enabled: false
            },
        xaxis: {
          categories: ['22/02','23/02','24/02','25/02','26/02','27/02','28/02','01/03','02/03','03/03','04/03','05/03']
        },
        yaxis: [{
          title:{
            text:'$ valor'
          }
        }]
      },
      series: [{
         name: 'Consumible',
        data: ['45927536','46224416','37855693','45318564','38483441','37732773','39885002','48628843','39134500','46965927','46407072','41688886'
        ]
      },{
         name: 'Critico',
        data: ['128431824',	'139209675',	'117949321',	'117945342',	'148086049',	'144883880',	'148036026',	'137718331',	'145869707',	'121492164',	'119844588',	'148426468'
]
      },{
         name: 'A pedido',
        data: ['223615569',	'288506533',	'278111934',	'208059420',	'256670232',	'170750025',	'208471422',	'209011642',	'276286837',	'55136606',	'139930869',	'286387244'
]
      },{
         name: 'Obsoleto',
        data: ['8541198',	'8637079',	'8133988',	'7636529',	'7360405',	'7867434',	'8528410',	'8036759',	'8863323',	'8744106',	'8475199',	'8517253'
]
      }],
    }
}
}
</script>
